


































































  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import Modal from '@/components/Modal.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import SupportCenterLink from '../SupportCenterLink.vue';

  export default Vue.extend({
    name: 'GenerateKeyModal',
    components: {
      Icon,
      LoadingButton,
      PasswordField,
      Modal,
      SupportCenterLink,
    },
    data() {
      return {
        loading: false,
        authenticationError: '',
        password: '',
        validate: false,
      };
    },
    watch: {
      password() {
        this.authenticationError = '';
      },
    },
    computed: {
      passwordLabelText(): string {
        return this.$gettext('Account password');
      },
    },
    methods: {
      ...mapActions(['setToastMessage', 'loadKeys']),
      openModal() {
        (this as any).$refs.modal.toggleModal();
      },
      closeModal() {
        (this as any).$refs.modal.toggleModal();
      },
      resetState() {
        this.password = '';
        this.validate = false;
        this.authenticationError = '';
      },
      async generateKey() {
        this.loading = true;

        try {
          this.validate = true;
          await api.keys.refresh({ password: this.password });

          this.setToastMessage({
            message: this.$gettext('New PGP key generated'),
          });
          this.loading = false;

          await this.$nextTick();

          this.closeModal();
        } catch (error: any) {
          const loginErrorCode = getErrorCode(error);
          if (loginErrorCode === AUTHENTICATION_FAILURE) {
            this.authenticationError = this.$gettext('Wrong password.');
            this.validate = true;
            this.$nextTick(() => {
              (this as any).$refs.password.focus();
            });
            this.loading = false;
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Unable to generate new PGP key, please try again'
              ),
            });

            this.loading = false;

            await this.$nextTick();
            this.closeModal();

            throw error;
          }
        } finally {
          this.loadKeys();
        }
      },
    },
  });
