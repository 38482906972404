






















































  import Vue from 'vue';
  import { mapGetters, mapState } from 'vuex';
  import GroupSubscriptionCreateAccountModal from '@/components/GroupSubscription/GroupSubscriptionCreateAccountModal.vue';
  import GroupSubscriptionCreateAccountNotifications from '@/components/GroupSubscription/GroupSubscriptionCreateAccountNotifications.vue';
  import formatCurrency from '@/lib/formatCurrency';
  import Modal from '@/components/Modal.vue';
  import GroupSubscriptionAccountPricing from '@/components/GroupSubscription/GroupSubscriptionAccountPricing.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import RibbonBanner from '@/components/RibbonBanner.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccountBanner',
    components: {
      GroupSubscriptionAccountPricing,
      GroupSubscriptionCreateAccountModal,
      GroupSubscriptionCreateAccountNotifications,
      Modal,
      ModalConfirmActions,
      RibbonBanner,
    },
    data() {
      return {
        isAddAccountModalOpen: false,
        accountPrice: '',
        accountDiscount: '',
        createGroupSubscriptionNotifications: {},
      };
    },
    created() {
      this.setBannerPricing();
    },
    computed: {
      ...mapGetters('subscription', ['isAccountCreateAllowed']),
      ...mapState('subscription', [
        'chargebeePlan',
        'currency',
        'groupSubscription',
      ]),
      accountPeriod(): string {
        return this.chargebeePlan?.billing_period === 'yearly'
          ? this.$gettext('per year')
          : this.$gettext('per month');
      },
      getAccountsQuantity(): number {
        return this.groupSubscription.accounts.length;
      },
      okText(): string {
        return this.$gettext('Create email account');
      },
      saveText(): string {
        return this.$gettextInterpolate(
          this.$pgettext('money', 'Save %{ amount }'),
          { amount: this.accountDiscount }
        );
      },
    },
    methods: {
      formatCurrency,
      setBannerPricing() {
        if (this.chargebeePlan) {
          const nextAccountTier = this.chargebeePlan.tiers.find(
            (tier: PlanTier) =>
              tier.starting_unit <= this.getAccountsQuantity + 1 &&
              (!tier.ending_unit ||
                tier.ending_unit >= this.getAccountsQuantity + 1)
          );

          this.accountPrice = this.formatCurrency(
            nextAccountTier.price,
            this.currency
          );

          const addOns =
            this.chargebeePlan.addons && this.chargebeePlan.addons.length
              ? this.chargebeePlan.addons[0].price
              : 0;
          const basePrice = this.chargebeePlan.tiers[0].price + addOns;

          this.accountDiscount = this.formatCurrency(
            basePrice - nextAccountTier.price,
            this.currency
          );
        }
      },
      openCreateGroupSubscriptionModal() {
        (this as any).$refs.groupSubscriptionCreateAccountModal.toggleModal();
      },
    },
    watch: {
      groupSubscription: {
        handler() {
          this.setBannerPricing();
        },
        deep: true,
      },
    },
  });
