var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"key-list"},[_vm._l((_vm.groupedKeys),function(group){return [(
        group.keys.length ||
        (!_vm.inSearch &&
          (group.id === _vm.KEY_GROUP_IDS.YOUR ||
            group.id === _vm.KEY_GROUP_IDS.PUBLIC))
      )?_c('section',{directives:[{name:"test",rawName:"v-test:keyGroup",value:(group.id),expression:"group.id",arg:"keyGroup"}],key:group.title,staticClass:"panel"},[_c('div',{staticClass:"key-list__top-wrap"},[(
            group.keys.length ||
            group.id === _vm.KEY_GROUP_IDS.YOUR ||
            group.id === _vm.KEY_GROUP_IDS.PUBLIC
          )?_c('h2',{directives:[{name:"test",rawName:"v-test:header",value:(group.id),expression:"group.id",arg:"header"}],staticClass:"panel__title"},[_vm._v(" "+_vm._s(group.title)+" ")]):_vm._e(),(group.id === _vm.KEY_GROUP_IDS.YOUR)?_c('GenerateKeyModal',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var toggle = ref.toggle;
return [_c('button',{directives:[{name:"test",rawName:"v-test:generateNewPgpKey",arg:"generateNewPgpKey"}],staticClass:"button button--accent button--small",attrs:{"type":"button"},on:{"click":toggle}},[_c('Icon',{attrs:{"symbol":"add"}}),_c('translate',[_vm._v("Generate new PGP key")])],1)]}}],null,true)}):_vm._e(),(group.id === _vm.KEY_GROUP_IDS.PUBLIC)?_c('ImportKeysModal',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var toggle = ref.toggle;
return [_c('button',{directives:[{name:"test",rawName:"v-test:importKey",arg:"importKey"}],staticClass:"button button--accent button--small",attrs:{"type":"button"},on:{"click":toggle}},[_c('Icon',{attrs:{"symbol":"add"}}),_c('translate',[_vm._v("Import key")])],1)]}}],null,true)}):_vm._e()],1),_c('div',{directives:[{name:"test",rawName:"v-test:table",value:(group.title),expression:"group.title",arg:"table"}],staticClass:"panel__section"},[(group.keys.length)?_c('transition-group',{key:((group.title) + "-list"),staticClass:"key-list",attrs:{"tag":"ul","name":"list"}},_vm._l((group.keys),function(key){return _c('li',{directives:[{name:"test",rawName:"v-test:key",arg:"key"}],key:key.key_id},[_c('router-link',{staticClass:"panel__section-item key-list__item",class:{ 'key--private': key.has_private_key },attrs:{"to":_vm.keyRoute(key.fingerprint)}},[_c('Icon',{staticClass:"key-list__item-icon",attrs:{"symbol":"key"}}),_c('div',{directives:[{name:"test",rawName:"v-test:keyName",arg:"keyName"}],staticClass:"key-list__item-name"},[_vm._v(" "+_vm._s(_vm.displayName(key))+" ")]),_c('div',{directives:[{name:"test",rawName:"v-test:keyId",arg:"keyId"}],staticClass:"key-list__item-id"},[_vm._v(" ID: "+_vm._s(key.key_id)+" ")]),_c('div',{directives:[{name:"test",rawName:"v-test:keyEmail",arg:"keyEmail"}],staticClass:"key-list__item-email"},[_vm._v(" "+_vm._s(_vm.emailAddress(key))+" ")]),_c('div',{directives:[{name:"test",rawName:"v-test:keyExpiryDate",arg:"keyExpiryDate"}],staticClass:"key-list__item-date",class:{
                  danger: key.expired || key.disabled || key.revoked,
                }},[_c('Icon',{attrs:{"symbol":"clock"}}),_c('span',[_vm._v(_vm._s(_vm.keyStatus(key)))])],1),_c('div',{directives:[{name:"test",rawName:"v-test:keyType",arg:"keyType"}],staticClass:"key-list__item-type"},[(key.has_private_key)?[_c('translate',[_vm._v("Private/Public")])]:[_c('translate',[_vm._v("Public")])]],2)],1)],1)}),0):_vm._e(),(!group.keys.length && group.emptyStateText)?_c('div',{staticClass:"panel__section-item"},[_c('p',[_vm._v(_vm._s(group.emptyStateText))])]):_vm._e()],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }