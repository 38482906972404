


















  import Vue from 'vue';
  import SettingsSidebar from '@/components/SettingsSidebar/SettingsSidebar.vue';

  export default Vue.extend({
    name: 'Settings',
    components: {
      SettingsSidebar,
    },
  });
