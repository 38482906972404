<template>
  <div class="row row--separated">
    <div class="col col--auto">
      <Modal :modal-loading="modalLoading" @modalToggled="reset">
        <template v-slot:toggle="{ toggle }">
          <button
            type="button"
            class="button button--accent button--small"
            @click="toggle"
            v-test:triggerButton
          >
            {{ triggerButtonText }}
          </button>
        </template>

        <template v-slot:header> {{ modalTitle }} </template>

        <template v-slot:content="modal">
          <form
            v-test:password-form
            v-if="!showQrCode"
            @submit.prevent="onConfirmClicked($event, modal)"
            novalidate
          >
            <PasswordField
              ref="password"
              id="password"
              :validate-input="validatePassword"
              :show-custom-validity="Boolean(passwordErrorMessage)"
              :validation-error-message="passwordErrorMessage"
              v-model="password"
              autocomplete="current-password"
            />
            <ModalConfirmActions
              :disabled="disablePasswordSubmit"
              :ok-text="triggerButtonText"
              @cancelClicked="modal.toggle"
            />
          </form>

          <form
            v-test:totp-form
            v-else-if="!showBackUpCode"
            @submit.prevent="verifyTotp($event)"
            novalidate
          >
            <div id="totp-setup-qrcode">
              <p class="spacing-bottom" v-translate>
                Two-factor authentication (2FA) improves the security of your
                account. When enabled, every login requires both your password
                and a one-time code generated by another device, typically your
                phone.
              </p>
              <p v-translate>
                If you lose access to the device that generates the one-time
                codes, you will need the backup code shown in the next step to
                access your account. Make sure to store it somewhere safe!
              </p>
              <div class="form-field">
                <h3 class="panel__section-title" v-translate>Step 1</h3>
                <p v-translate>
                  Open a two-factor authentication app on your device and scan
                  this QR code:
                </p>
                <div class="qr-code-wrapper">
                  <QrcodeVue
                    class="qr-code dark-mode-reset"
                    :value="qrCode.uri"
                    size="200"
                  />
                </div>
                <p v-translate>
                  Alternatively, copy and paste the following code:
                </p>
                <div class="secret-code">
                  {{ formattedSecretCode }}
                  <button
                    @click="copyToClipboard(formattedSecretCode)"
                    type="button"
                    class="button button--small"
                    :aria-label="copyText"
                  >
                    <Icon symbol="copy" />
                  </button>
                </div>
              </div>

              <div class="form-field">
                <h2 class="panel__section-title" v-translate>Step 2</h2>
                <p v-translate>Enter the one-time code from the app:</p>
              </div>

              <InputField
                label=""
                :aria-label="oneTimeCodeLabel"
                autocomplete="one-time-code"
                autofocus
                class="form-field"
                id="authentication-code"
                inputmode="numeric"
                :maxlength="6"
                ref="totpCode"
                required
                :show-custom-validity="Boolean(TOTPError)"
                :validate="validateAuthenticationCode"
                :validation-error-message="
                  TOTPError || authenticationCodeErrorText
                "
                v-model="authenticationCode"
              />
            </div>

            <ModalConfirmActions
              :disabled="authenticationCode === '' || Boolean(TOTPError)"
              :ok-text="activateText"
              @cancelClicked="modal.toggle"
            />
          </form>

          <TotpDeactivationCodeForm
            v-else
            @submit.prevent="modal.toggle"
            :deactivation-code="qrCode.backup_code"
          >
            <template v-slot:information>
              <p v-translate>
                Success! If you lose your authentication device, you can use
                this two-factor authentication backup code to gain access to
                your account.
              </p>
            </template>
          </TotpDeactivationCodeForm>
        </template>
      </Modal>
    </div>

    <div class="col col--auto">
      <Modal @modalToggled="reset">
        <template v-slot:toggle="{ toggle }">
          <button
            type="button"
            class="button button--accent button--small"
            @click="toggle"
            :disabled="!totp_enabled"
            v-test:view2FaCode
          >
            <span v-translate>View backup code</span>
          </button>
        </template>
        <template v-slot:header>
          <translate>Enter your password to view your backup code</translate>
        </template>
        <template v-slot:content="{ toggle }">
          <form
            v-test:password-form
            v-if="!show2FaCode"
            @submit.prevent="viewBackUpCode"
            novalidate
          >
            <PasswordField
              ref="password"
              id="password-2fa"
              :validate-input="validatePassword"
              :show-custom-validity="Boolean(passwordErrorMessage)"
              :validation-error-message="passwordErrorMessage"
              v-model="password"
              autocomplete="current-password"
            />
            <ModalConfirmActions
              :disabled="disablePasswordSubmit"
              :ok-text="showBackupCodeText"
              @cancelClicked="toggle"
            />
          </form>
          <TotpDeactivationCodeForm
            v-else
            @submit.prevent="toggle"
            :deactivation-code="backup_code"
          />
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
  import api from '@/api';
  import Icon from '@/components/Icon/Icon';
  import Modal from '@/components/Modal';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions';
  import PasswordField from '@/components/PasswordField/PasswordField';
  import TotpDeactivationCodeForm from '@/components/TotpDeactivationCodeForm/TotpDeactivationCodeForm';
  import InputField from '@/components/form/InputField';
  import formatLongNumber from '@/lib/formatLongNumber';
  import asyncActionsMixin, { asyncAction } from '@/mixins/async-actions-mixin';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'TotpSetUpModal',
    components: {
      Icon,
      InputField,
      Modal,
      PasswordField,
      TotpDeactivationCodeForm,
      ModalConfirmActions,
      QrcodeVue: () => import(/* webpackChunkName: "qr-code" */ 'qrcode.vue'),
    },
    mixins: [asyncActionsMixin],
    data() {
      return {
        validatePassword: false,
        validateAuthenticationCode: false,
        passwordErrorMessage: '',
        TOTPError: '',
        password: '',
        showQrCode: false,
        qrCode: {
          uri: '',
          backup_code: '',
        },
        authenticationCode: '',
        showBackUpCode: false,
        show2FaCode: false,
        backup_code: '',
      };
    },
    computed: {
      ...mapState({
        totp_enabled: (state) => state.authentication.user.totp_enabled,
        email: (state) => state.authentication.user.email,
      }),
      formattedSecretCode() {
        const searchString = this.qrCode.uri.substr(
          this.qrCode.uri.indexOf('?')
        );
        const secret = new URLSearchParams(searchString).get('secret') || '';
        return formatLongNumber(secret);
      },
      oneTimeCodeLabel() {
        return this.$gettext('One-time code');
      },
      activateText() {
        return this.$gettext('Activate');
      },
      modalLoading() {
        return this.isBusy.verifyingTotp || this.isBusy.togglingTotp;
      },
      triggerButtonText() {
        return this.totp_enabled
          ? this.$gettext('Disable')
          : this.$gettext('Enable');
      },
      modalTitle() {
        if (this.totp_enabled && !this.showQrCode && !this.showBackUpCode) {
          return this.$gettext('Disable two-factor authentication');
        } else if (
          !this.totp_enabled &&
          this.showQrCode &&
          !this.showBackUpCode
        ) {
          return this.$gettext('Activate two-factor authentication');
        } else if (
          this.totp_enabled &&
          this.showQrCode &&
          this.showBackUpCode
        ) {
          return this.$gettext('Two-factor authentication back up code');
        }
        return this.$gettext('Enable two-factor authentication');
      },
      okText() {
        return this.$gettext('Close');
      },
      disablePasswordSubmit() {
        return this.password === '' || Boolean(this.passwordErrorMessage);
      },
      authenticationCodeErrorText() {
        return this.$gettext('Enter a valid one-time code.');
      },
      showBackupCodeText() {
        return this.$gettext('Show backup code');
      },
      copyText() {
        return this.$gettext('Copy to clipboard');
      },
    },
    watch: {
      authenticationCode() {
        this.TOTPError = '';
      },
      password() {
        this.passwordErrorMessage = '';
        this.validatePassword = false;
      },
    },
    methods: {
      ...mapActions(['setToastMessage']),
      ...mapActions('authentication', ['totp', 'disableTotp']),
      reset() {
        this.show2FaCode = false;
        this.showBackUpCode = false;
        this.validatePassword = false;
        this.showQrCode = false;
        this.authenticationCode = '';
        this.password = '';
        this.validateAuthenticationCode = false;
      },
      setupTotp(password) {
        return api.authentication
          .setupTotp({ password })
          .then(({ uri, backup_code }) => {
            this.qrCode = { uri, backup_code };
            this.showQrCode = true;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.passwordErrorMessage = this.$gettext('Wrong password.');
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not set up two-factor authentication. Please try again later'
                ),
              });
              throw error;
            }
          });
      },
      verifyTotp: asyncAction('verifyingTotp', function (ev) {
        const token = ev.target.elements['authentication-code'].value;
        this.validateAuthenticationCode = true;
        if (!ev.target.checkValidity()) {
          return;
        }
        return this.totp({ email: this.email, password: this.password, token })
          .then(() => {
            this.showBackUpCode = true;
            this.setToastMessage({
              message: this.$gettext(
                'Two-factor authentication is successfully enabled for your account'
              ),
            });
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.TOTPError = this.$gettext('Incorrect one-time code');
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not enable two-factor authentication. Please try again later'
                ),
              });
              throw error;
            }
          });
      }),
      viewBackUpCode: asyncAction('loadingBackUpCode', function (ev) {
        const password = ev.target.elements['password-2fa'].value;
        this.validatePassword = true;
        if (!ev.target.checkValidity()) {
          return;
        }
        return api.authentication
          .backupCode({ password })
          .then(({ backup_code }) => {
            this.show2FaCode = true;
            this.backup_code = backup_code;
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              this.passwordErrorMessage = this.$gettext('Wrong password.');
            } else {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not show your backup code. Please try again later'
                ),
              });
              throw error;
            }
          });
      }),
      onConfirmClicked: asyncAction('togglingTotp', function (ev, modal) {
        const password = ev.target.elements['password'].value;
        this.validatePassword = true;

        if (!ev.target.checkValidity()) {
          return;
        }
        if (this.totp_enabled) {
          return this.disableTotp({ password })
            .then(() => {
              this.setToastMessage({
                message: this.$gettext(
                  'Two-factor authentication is disabled for your account'
                ),
              });
              modal.toggle();
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                this.passwordErrorMessage = this.$gettext('Wrong password.');
              } else {
                this.setToastMessage({
                  message: this.$gettext(
                    'Sorry, we could not disable two-factor authentication. Please try again later'
                  ),
                });
                throw error;
              }
            });
        }
        return this.setupTotp(password);
      }),
      async copyToClipboard(value) {
        try {
          await navigator.clipboard.writeText(value);
          this.setToastMessage({
            message: this.$gettext('Code copied to clipboard'),
          });
        } catch (err) {
          // unable to write text to clipboard
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .qr-code-wrapper {
    display: flex;
    justify-content: center;

    .qr-code {
      border: $spacing-default solid $white; // improve scanning against any background color
    }
  }

  .secret-code {
    align-items: center;
    display: flex;
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    gap: $spacing-xxs;
    justify-content: center;
    margin-block: $spacing-default;
    text-align: center;
    text-wrap: balance;
  }
</style>
