


















































  import Vue from 'vue';
  import GroupSubscriptionPlanCard, {
    PlanDetail,
  } from '@/components/GroupSubscription/GroupSubscriptionPlanCard.vue';
  import GroupSubscriptionAccountCalculator from '@/components/GroupSubscription/GroupSubscriptionAccountCalculator.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import GroupSubscriptionCreateAccountModal from '@/components/GroupSubscription/GroupSubscriptionCreateAccountModal.vue';
  import GroupSubscriptionCreateAccountNotifications from '@/components/GroupSubscription/GroupSubscriptionCreateAccountNotifications.vue';
  import { mapGetters, mapState } from 'vuex';
  import GroupSubscriptionWarning from '@/components/GroupSubscription/GroupSubscriptionWarning.vue';

  export default Vue.extend({
    name: 'GroupSubscriptionAccountPricing',
    components: {
      GroupSubscriptionPlanCard,
      GroupSubscriptionAccountCalculator,
      Icon,
      GroupSubscriptionCreateAccountModal,
      GroupSubscriptionCreateAccountNotifications,
      GroupSubscriptionWarning,
    },
    props: {
      isModal: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        availablePlans: [] as PlanDetail[],
        createGroupSubscriptionNotifications: {},
      };
    },
    computed: {
      ...mapGetters('subscription', ['isAccountCreateAllowed']),
      ...mapState('subscription', ['chargebeePlan']),
      additionalAccountsHtml(): string {
        return this.$gettext(
          "<strong>Set up additional accounts for family or team members using an extended subscription. You can create as many users as you want.</strong> The amount charged today will be prorated based on time left until your subscription renews; e.g. if you have 6 months left until your subscription renews and you're on a yearly plan, you will only pay half of the annual cost for an additional email account during this subscription period."
        );
      },
    },
    created() {
      this.initializePlan();
    },
    methods: {
      buildPlanDetail(
        id: number,
        plan: PlanTier,
        addonPrice: number,
        isDiscount: boolean,
        currentYearlyPrice: number
      ) {
        const totalPrice = plan.price + addonPrice;
        return {
          id: id,
          minMembers: plan.starting_unit,
          maxMembers: plan.ending_unit ?? -1,
          pricePerMonth:
            this.chargebeePlan?.billing_period === 'yearly'
              ? Math.ceil(totalPrice / 12)
              : totalPrice,
          pricePerYear: totalPrice,
          isDiscount: isDiscount,
          discountAmount: isDiscount ? currentYearlyPrice - totalPrice : 0,
        };
      },
      initializePlan() {
        let basePrice = this.chargebeePlan?.tiers[0].price;
        if (this.chargebeePlan?.addons && this.chargebeePlan?.addons.length) {
          basePrice = basePrice + this.chargebeePlan?.addons[0].price;
        }
        this.availablePlans = this.chargebeePlan?.tiers.map(
          (plan: PlanTier, index: number) => {
            if (plan.starting_unit === 1 && plan.ending_unit === 1) {
              const addonPrice =
                this.chargebeePlan?.addons && this.chargebeePlan?.addons.length
                  ? this.chargebeePlan?.addons[0].price
                  : 0;
              return this.buildPlanDetail(index, plan, addonPrice, false, 0);
            } else {
              return this.buildPlanDetail(index, plan, 0, true, basePrice);
            }
          }
        );
      },
    },
  });
